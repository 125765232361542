<template>
    <div v-if="iconBadges.length" class="badges-container">
        <div class="verification-badges" v-for="badge in iconBadges" :key="badge.name">
            <div @click="fireModal(badge)" :class="{ wrapper: badge.display_method.includes('photo') }">
                <div
                    v-lazy:background-image="
                        require(`@/assets/images/verification-badge/active/${badge.image_name}.svg`)
                    "
                    class="badge-img"
                />
            </div>
            <span class="f-bold text-nowrap" v-html="badge.name" />
        </div>
    </div>
</template>

<script>
export default {
    name: 'TodayMeetingBadges',
    props: {
        verificationBadges: Array,
    },
    computed: {
        verificationBadgesAll() {
            const badges = this.verificationBadges.filter(
                ({ confirmed, show_profile: show, display_method: dMethod }) => {
                    if (this.from === 'todaymeet') {
                        return confirmed && show && dMethod !== 'photo'
                    } else return confirmed && show
                },
            )
            badges.sort((badgeA, badgeB) => {
                if (!(badgeA.display_method || '').includes('photo') && (badgeB.display_method || '').includes('photo'))
                    return -1

                if (badgeA.category === badgeB.category) return badgeA.position - badgeB.position
            })

            return badges
        },
        iconBadges() {
            // 기획 의도 상 사진이 있는 매력뱃지 제외

            if (!this.verificationBadgesAll.length) return []

            return this.verificationBadgesAll.filter(badge => (badge.display_method || []).includes('icon'))
        },
    },
}
</script>

<style scoped lang="scss">
.badges-container {
    margin: 16px 0 18px 0;
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    justify-content: center;

    .verification-badges {
        width: 57px;
        @include flex;

        justify-content: center;
        align-items: center;

        .wrapper {
            position: relative;

            img {
                position: absolute;
                left: 50%;
                top: 50%;
                transform: translate(-50%, -50%);
                width: 35px;
                height: 35px;
                border-radius: 50%;
            }
        }

        span {
            margin-top: 4px;
            font-size: 12px;
            color: $purple-primary;
        }
    }

    .badge-img {
        width: 44px;
        height: 44px;
        background-size: cover;
    }
}
</style>
