<template>
    <div class="modal-basic" v-if="show">
        <div class="head">
            <i class="material-icons" @click="close">close</i>
        </div>
        <div class="body" :class="{ 'm-t-0': options.hideHeader }">
            <div class="profile-info">
                <img v-if="profileStyle === 'mini'" class="profile-photo" :src="hostInfo.primary_photo" alt="" />
                <div v-else class="profile-photo privacy">
                    <i class="material-icons">person</i>
                </div>
                <div class="nickname" v-html="hostInfo.nickname" />
                <div class="info" v-html="miniOrPrivacy" />
                <div class="summary-badge" v-if="badgeTitle">
                    <span class="badge-title">{{ badgeTitle }}</span>
                    <span>인증 완료</span>
                    <i class="material-icons">admin_panel_settings</i>
                </div>
            </div>
            <div class="badges-info">
                <TodayMeetingBadges :verificationBadges="allBadges" />
            </div>
        </div>
    </div>
</template>
<script>
import TodayMeetingBadges from '@/routes/today-meeting/components/TodayMeetingBadges'

export default {
    name: 'ModalProfileView',
    props: ['options'],
    components: {
        TodayMeetingBadges,
    },

    mounted() {
        document.addEventListener('keydown', this.onKeydown)
    },
    beforeDestroy() {
        document.removeEventListener('keydown', this.onKeydown)
    },
    computed: {
        hostInfo() {
            return this.options.hostInfo
        },
        profileStyle() {
            return this.options.profileStyle
        },
        badgeTitle() {
            return this.options.badgeTitle
        },
        allBadges() {
            return this.options.allBadges
        },
        miniOrPrivacy() {
            const mini = this.profileStyle === 'mini'
            const gender = this.hostInfo.gender === 0 ? '남' : '여'
            const age = mini ? `${this.hostInfo.age}세` : this.$options.filters.blindAge(this.hostInfo.age)

            return `${gender} (${age})${mini ? `, ${this.hostInfo.job_name}` : ''}`
        },
    },
    created() {
        this.init()
    },
    data: () => ({
        // jobName: '',
        // badgesAll: [],
        show: false,
    }),
    methods: {
        async init() {
            // const {
            //     profile: { job_name: jobName },
            // verification_badges: badgesAll,
            // } = await userService.detail(this.hostInfo.id)
            this.show = true
            // this.jobName = jobName
            // this.badgesAll = badgesAll
        },
        onClickButton(btn) {
            this.$emit('close', this.buttons.indexOf(btn))
        },
        onKeydown(event) {
            if (event.key === 'Enter' && this.buttons) {
                const confirmButton = this.buttons[this.buttons.length - 1]
                if (confirmButton) this.onClickButton(confirmButton)
            }
        },
        close() {
            this.$emit('close')
        },
    },
}
</script>
<style scoped lang="scss">
.head {
    position: relative;
    float: right;
    padding: 0;

    i {
        position: absolute;
        right: 0;
        top: 0;
    }
}
.body {
    padding: 0;

    .profile-photo {
        width: 64px;
        height: 64px;
        border-radius: 25.6px;
        border: 1px solid $grey-03;
        margin: 12px auto;

        &.privacy {
            background: $grey-03;
            @include center;

            i {
                font-size: 32px;
                color: $grey-05;
            }
        }
    }

    .nickname {
        font-size: 16px;
        margin-bottom: 4px;
        @include f-medium;
    }
    .info {
        font-size: 14px;
        color: $grey-08;
    }
    .summary-badge {
        font-size: 12px;
        margin-top: 24px;
        color: $purple-primary;
        @include center;

        .badge-title {
            text-decoration: underline;
            margin-right: 4px;
            @include f-bold;
        }

        i {
            color: $purple-primary;
            margin-left: 4px;
            font-size: 18px;
        }
    }
}
</style>
